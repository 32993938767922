/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

import CopyrightInfo from "../copyright-info"
import SiteLinks from "../site-links"
import { Separator } from "../gatsby-sidebar/docs-breadcrumb"

export default ({ doc }) => (
  <div
    sx={{
      maxWidth: `42rem`,
      m: `0 auto`,
      position: `static`,
      padding: `0rem 1.5rem 1.5rem`,
    }}
  >
    <div>
      <h1>{doc.frontmatter.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: doc.html}} />
    </div>
    
    <div>← previous item next item →</div>
    <SiteLinks>
      <Link to="/">Landing Page</Link>
      <Separator character={<span>{` `}</span>} />
      <Link to="/index-blog/">Blog</Link>
      <Separator character={<span>{` `}</span>} />
      <Link to="/index-project/">Projects</Link>
    </SiteLinks>

    <CopyrightInfo />
  </div>
)