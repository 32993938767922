/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumb from "../components/gatsby-sidebar/docs-breadcrumb"
import { PageWithSidebar } from "../components/gatsby-sidebar/page-with-sidebar"
import MainContent from "../components/gatsby-sidebar/main-content"

const TemplateDocsMarkdown = ({ data, pageContext, location }) => {
  const doc = data.markdownRemark
  
  return (
    <Layout>
      <SEO title={"Gatsby Docs Mockup Project - " + doc.frontmatter.title} />
      <PageWithSidebar location={location}>
        <main
          id="reach-skip-nav"
          className="docSearch-content"
          sx={{position: `relative`,}}
        >
          <Breadcrumb location={location} />
          <MainContent doc={doc} />
        </main>
      </PageWithSidebar>
    </Layout>
  )
}

export default TemplateDocsMarkdown

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
