/** @jsx jsx */
import { jsx } from "theme-ui"
import { GoFold, GoUnfold } from "react-icons/go"

export default ({isOpen, onClick}) => (
  <button
    sx={{
      alignItems: `center`,
      backgroundColor: `transparent`,
      border: `none`,
      borderRadius: `2px`,
      color: `textMuted)`,
      cursor: `pointer`,
      display: `flex`,
      flexGrow: 0,
      fontSize: `0.75rem`,
      lineHeight: 1,
      p: `0.5rem 0.5rem`,
      textAlign: `left`,
      transition: `all 100ms`,
      "&:hover": {
        backgroundColor: `sidebar.itemHoverBackground`,
        color: `navigation.linkHover`,
      },
    }}

    onClick={() => onClick(isOpen)}
  >
    {isOpen ? <GoFold /> : <GoUnfold />}
    <span
      sx={{ pl: `0.5rem` }}
    >
      {isOpen ? "Expand All" : "Collapse All"}
    </span>

  </button>
)