/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"

import { getItemList } from "../../utils/gatsby-sidebar/item-list"
import { StickyResponsiveSidebar } from "./sticky-responsive-sidebar"

export const PageWithSidebar = ({ children, location }) => {
  const docItemList = getItemList(location.pathname)

  if (!docItemList) {
    return children
  }

  return (
    <Fragment>
      <div
        sx={{ paddingLeft: `18rem` }}
      >
        {children}
      </div>

      <StickyResponsiveSidebar
        itemList={docItemList.items}
        title={docItemList.title}
        location={location}
      />
    </Fragment>
  )
}
