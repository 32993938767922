/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
import { mediaQueries } from "gatsby-design-tokens/dist/theme-gatsbyjs-org"

import Item from "./item"
import ExpandAllButton from "./expand-all-button"

export const StickyResponsiveSidebar = ({
  itemList,
  title,
  location
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const onClickHandler = isOpen => setIsOpen(!isOpen)
  return (
    <div
      sx={{
        border: 0,
        bottom: 0,
        display: `block`,
        height: `100vh`,
        opacity: 0,
        pointerEvents: `none`,
        position: `fixed`,
        top: `0rem`,
        width: `320px`,
        zIndex: 10,
        [mediaQueries.md]: {
          height: `calc((100vh - 4rem) - 2rem)`,
          maxWidth: `none`,
          opacity: `1 !important`,
          pointerEvents: `auto`,
          top: `calc(6rem)`,
          width: `16.5rem`,
        },
        [mediaQueries.lg]: {
          width: `18rem`
        }
      }}
    >
      <div
        sx={{
          boxShadow: `0px 4px 16px rgba(46,41,51,0.08), 0px 8px 24px rgba(71,63,79,0.16)`,
          height: `100%`,
          transform: `translateX(-320px)`,
          transition: `transform 250ms cubic-bezier(0.4,0,0.2,1)`,
          [mediaQueries.md]: {
            boxShadow: `none`,
            transform: `none !important`,
          },
        }}
      >
        <section
          aria-label="Secondary Navigation"
          id="SecondaryNavigation"
          className="docSearch-sidebar"
          sx={{ height: `100%`, }}
        >
          <header
            sx={{
              alignItems: `center`,
              backgroundColor: `background`,
              borderColor: `ui-border`,
              borderRightStyle: `solid`,
              borderRightWidth: `1px`,
              display: `flex`,
              height: `3.5rem`,
              pl: `1rem`,
              pr: `1.5rem`,
              webkitBoxAlign: `center`,
            }}
          >
            <ExpandAllButton isOpen={isOpen} onClick={onClickHandler} />
          </header>
          <nav
            sx={{
              backgroundColor: `background`,
              border: 0,
              display: `block`,
              overflowY: `auto`,
              transition: `opacity 250ms cubic-bezier(0.4,0,0.2,1)`,
              zIndex: 10,
              borderRightWidth: `1px`,
              borderRightStyle: `solid`,
              borderColor: `ui-border`,
              height: `calc(100% - 3.5rem)`,
              [mediaQueries.md]: {
                top: `calc(4rem + 2 rem)`,
              },
            }}
          >
            <h3
              sx={{
                color: `textMuted`,
                fontSize: `0.875rem`,
                fontWeight: 400,
                letterSpacing: `0.075em`,
                m: 0,
                p: `1.5rem 1.5rem 0`,
                textTransform: `uppercase`,
               }}
            >
                {title}
            </h3>
            <ul
              sx={{
                listStyle: `none`,
              }}
            >
              {itemList.map(
                item => <Item item={item} key={item.title}/>
              )}
            </ul>        
          </nav>
        </section>
      </div>
    </div>
  )
}

