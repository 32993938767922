/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment, useState } from "react"
import { Link } from "gatsby"

import ChevronSvg from "./chevron-svg"
import Item from "./item"

const ItemLink = ({ link, title }) => {
  return (
      <span
      sx={{
        alignItems: `center`,
        display: `flex`,
        position: `relative`,
      }}
    >
      <Link
        to={link}
        sx={{
          color: `var(--theme-ui-colors-navigation-linkDefault,#48434f)`,
          fontSize: `0.875rem`,
          letterSpacing: `0.075em`,
          minHeight: `2.5rem`,
          position: `relative`,
          pl: `1.5rem`,
          pr: `1rem`,
          py: `0.75rem`,
          textDecoration: `none`,
          textTransform: `uppercase`,
          width: `100%`,
          zIndex: 1,
        }}
      >
        {title}
      </Link>
    </span>
  )
}

// const createHash = ({ location }) => {}
export default ({ item }) => {
  const [open, setOpen] = useState(false)
  const toggleAccordion = () => setOpen(open => !open)

  return (
    <Fragment>
      <li
        sx={{
          backgroundColor: `background`,
          postion: `relative`,
          transition: `all 100ms cubic-bezier(0.4,0,0.2,1)`,
          "&:before": {
            content: `" "`,
            position: `absolute`,
            borderColor: `ui-border`,
            borderTopStyle: `solid`,
            borderTopWidth: `1px`,
            left: 0,
            right: 0,
            top: 0,
          }
        }}
      >
        <span
          sx={{
            alignItems: `flex-end`,
            display: `flex`,
            position: `relative`,
            width: `100%`,
          }}
        >
          <span sx={{ flexGrow: 1, }}>
            <ItemLink link={item.link} title={item.title}/>
          </span>
          <button
            sx={{
              backgroundColor: `transparent`,
              border: 0,
              bottom: 0,
              cursor: `pointer`,
              p: 0,
              ml: `auto`,
              minHeight: `2.5rem`,
              position: `absolute`,
              right: 0,
              top: 0,
              width: `2.5rem`,
              zIndex: 1,
            }}
            onClick={toggleAccordion}
          >
            <ChevronSvg cssProps={{ transform: open ? `rotate(0.75turn)` : `rotate(0.5turn)` }} />
          </button>
        </span>
      </li>
      {open ?
        null :

        <ul
          sx={{
            listStyle: `none`,
          }}
        >
          {item.items.map(item => {
            return item.items ?
              <Item item={item} key={item.title} /> :
              (
                <li key={item.title}>
                  <Link
                    to={item.link}
                    sx={{
                      color: `inherit`,
                      textDecoration: `none`,
                    }}
                  >
                    {item.title}
                  </Link>
                </li>
              )
          })}
        </ul>}
    </Fragment>
  )
}
