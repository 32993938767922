/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import Accordion from "./accordion"

export default ({ item }) => {
  if (item.items) {
    return <Accordion item={item} />
  }
  else {
    return (
      <li key={item.title}>
        <Link
          to={item.link}
          sx={{
            color: `inherit`,
            textDecoration: `none`,
            "&:hover": {
              backgroundColor: `sidebar.itemHoverBackground`,
              color: `navigation.linkHover`,
            },
          }}
        >
          {item.title}
        </Link>
      </li>
    )
  }
}
